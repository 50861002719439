import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';
import { Seo } from '~/models/seo';

export namespace Manager {
	export type Advantage = {
		advantage: string;
	};

	export type Model = Seo.Model &
		Common.SingleModel & {
			photo: Image.Model;
			name: string;
			title: string;
			text: string;
			advantages: Advantage[];
			button: string;
			form: Form.Model;
			seo: Seo.Model;
		};

	export const modelState: Model = {
		...Seo.modelState,
		...Common.singleModelState,
		photo: Image.modelState,
		name: '',
		title: '',
		text: '',
		advantages: [],
		button: 'Записаться на консультацию',
		form: Form.modelState,
		seo: Seo.modelState,
	};
}
