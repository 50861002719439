import { Manager } from '~/models/manager';

const collection = 'manager';

export const managerService = {
	fetch: async (): Promise<Manager.Model> => {
		const { getSingletonItem } = useDirectusItems();

		return await getSingletonItem<Manager.Model>({
			collection,
			params: {
				fields: ['*', 'photo.*', 'form.*', 'seo.*'],
			},
		});
	},
};
