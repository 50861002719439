import { managerService } from '~/services/manager';
import { Manager } from '~/models/manager';

export const useManagerState = () => ({
	managerState: useState<Manager.Model>('managerState', () => Manager.modelState),
	managerLoaded: useState<boolean>('managerLoaded', () => false),
	managerPending: useState<boolean>('managerPending', () => true),
});

export const useManager = () => {
	const { managerState, managerLoaded, managerPending } = useManagerState();

	/**
	 * Fetch manager
	 */
	const fetchManager = async () => {
		if (managerLoaded.value) return;

		managerPending.value = true;

		managerState.value = await managerService.fetch();

		managerLoaded.value = true;
		managerPending.value = false;
	};

	/**
	 * Refresh manager
	 */
	const refreshManager = async () => {
		managerLoaded.value = false;

		await fetchManager();
	};

	return {
		managerState,
		managerPending,

		fetchManager,
		refreshManager,
	};
};
