<template lang="pug">
div(class='SliderBenefits')
	UiCarousel(
		class='SliderBenefits--Carousel',
		:perView='greaterThan("mobileWide") ? 3.4 : greaterThan("mobileMedium") ? 2.4 : 1.4',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(v-for='benefit in benefits', :key='benefit.id', v-bind='carouselItemProps')
					template(v-if='benefit.type === "benefit"')
						div(class='SliderBenefits--Carousel--Item')
							div(class='Benefit') {{ benefit.benefit }}
							div(class='BenefitText') {{ benefit.benefitText }}
					template(v-if='benefit.type === "employee"')
						NuxtLink(
							class='SliderBenefits--Carousel--Item',
							:to='getEmployeePageUrlByAlias(benefit.employee.alias)'
						)
							div(class='Photo')
								UiImage(:image='benefit.employee.photo', width='60px', lazy)
							div(class='Name')
								span {{ benefit.employee.name }}
								span {{ benefit.employee.surname }}
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';
import { UiCarousel } from '#components';
import { Benefits } from '~/models/benefits';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { getPageUrlByType, fetchResolver } = useResolver();

/**
 * Define props
 */
const { benefits = [] } = defineProps<{
	benefits: Benefits.Model[];
}>();

/**
 * Get employee url
 */
const getEmployeePageUrlByAlias = (alias: string) =>
	joinURL(getPageUrlByType('employees') || '', alias);

/**
 * Define fetch
 */
await fetchResolver();
</script>

<style lang="less" scoped>
.SliderBenefits {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&--Carousel {
		.grid(1 span, 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			padding: 1rem 1.25rem;
			text-decoration: none;
			.box(grid; 100%; 100%; @ColorWhite; 1rem; 1fr; auto 1fr; center; start);
			.border-radius(@BorderRadius);

			& > .Photo {
				.grid(1 span);
				.relative(1; hidden);
				.box(flex; 3.125rem; 3.125rem; fade(@ColorBase, 15%); start; center; center; nowrap row);
				.border-radius(@BorderRadiusFull);
				& > img {
					object-fit: cover;
					.box(100%; auto);
				}
			}
			& > .Name {
				.grid(1 span);
				.text(@ColorBase; 1.25em 0.75rem @medium; left; none none);
				.box(block; auto; auto; none);
				& > span {
					.box(block; auto; auto; none);
				}
			}

			& > .Benefit {
				.grid(1 span);
				.text(@ColorWhite; 1em 0.875rem @semibold; left);
				.box(flex; 3.125rem; 3.125rem; @ColorBase; center; center; center; nowrap row);
				.border-radius(@BorderRadiusFull);
			}
			& > .BenefitText {
				.grid(1 span);
				.text(@ColorBase; 1.25em 0.75rem @medium; left);
			}
		}
	}
}
</style>
