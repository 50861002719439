import { Common } from '~/models/common';
import { Benefits } from '~/models/benefits';

const collection = 'benefits';

export const benefitsService = {
	fetch: async (): Promise<Benefits.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Benefits.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: [
					'*',
					'seo.title',
					'seo.header',
					'seo.keywords',
					'seo.description',
					'employee.*',
					'employee.photo.*',
				],
			},
		});
	},
};
