<template lang="pug">
h2(v-if='lessThan("tablet")', class='color-primary') {{ variablesList.benefitsHeader }}
div(v-if='lessThan("tablet")', class='HeaderDescription') {{ variablesList.benefitsTitle }} {{ variablesList.benefitsSubtitle }}
h2(v-if='greaterThan("mobileWide")', class='text-center short-text') 
	| {{ variablesList.benefitsTitle }}
	|
	span(class='Accent') {{ variablesList.benefitsSubtitle }}

div(class='Benefits')
	SliderBenefits(v-if='lessThan("desktop")', :benefits='benefitsState')
	div(v-else, class='Benefits--list')
		template(v-for='benefit in benefitsState', :key='benefit.id')
			div(v-if='benefit.type === "benefit"', class='Benefits--list--Item', :class='`size-${benefit.size}`')
				div(class='Benefit') {{ benefit.benefit }}
				div(class='BenefitText') {{ benefit.benefitText }}
			NuxtLink(
				v-if='benefit.type === "employee"',
				:to='getEmployeePageUrlByAlias(benefit.employee.alias)',
				class='Benefits--list--Item',
				:class='`size-${benefit.size}`'
			)
				div(class='Photo')
					UiImage(:image='benefit.employee.photo', width='120px', lazy)
				div(class='Name')
					span {{ benefit.employee.name }}
					span {{ benefit.employee.surname }}

	div(class='Benefits--button')
		UiButton(theme='filled', size='xl', rounded, :to='getPageUrlByType("employees")') {{ variablesList.benefitsButton }}
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';

/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();
const { getPageUrlByType, fetchResolver } = useResolver();
const { benefitsState, fetchBenefits } = useBenefits();

/**
 * Get employee url
 */
const getEmployeePageUrlByAlias = (alias: string) =>
	joinURL(getPageUrlByType('employees') || '', alias);

/**
 * Define fetch
 */
await fetchVariables();
await fetchResolver();
await fetchBenefits();
</script>

<style lang="less" scoped>
.Benefits {
	margin: 1.25rem 0 0 0;
	.box(grid; 100%; auto; none; 2rem; auto; 100%; center; center);
	& > .SliderBenefits {
		.grid(1 span);
	}
	&--list {
		margin: 0 -1rem;
		.grid(1 span);
		.box(flex; auto; auto; none; center; center; center; wrap row);
		.calc(width; ~'calc(100% + 2rem)');
		&--Item {
			margin: 0.625rem 0.875rem;
			padding: 1rem 2rem 1rem 1rem;
			text-decoration: none;
			.flex(0 0 auto);
			.box(grid; auto; auto; @ColorWhite; 1rem; 1fr; auto 1fr; center; start);
			.border-radius(@BorderRadiusFull);
			& > .Photo {
				.grid(1 span);
				.relative(1; hidden);
				.box(flex; 3.125rem; 3.125rem; fade(@ColorBase, 15%); start; center; center; nowrap row);
				.border-radius(@BorderRadiusFull);
				& > img {
					margin-top: 0.5em;
					object-fit: cover;
					.box(100%; auto);
				}
			}
			& > .Name {
				.grid(1 span);
				.text(@ColorBase; 1.25em 1rem @medium; left; none none);
				.box(block; auto; auto; none);
				& > span {
					.box(block; auto; auto; none);
				}
			}
			& > .Benefit {
				.grid(1 span);
				.text(@ColorWhite; 1em 0.875rem @semibold; left);
				.box(flex; 3.125rem; 3.125rem; @ColorBase; center; center; center; nowrap row);
				.border-radius(@BorderRadiusFull);
			}
			& > .BenefitText {
				.grid(1 span);
				.text(@ColorBase; 1.25em 1rem @medium; left);
			}

			&.size-1 {
				max-width: 16rem;
				& > .Photo {
					.box(2.5rem; 2.5rem);
				}
				& > .Benefit {
					font-size: 0.875rem;
					.box(2.5rem; 2.5rem);
				}
			}
			&.size-2 {
				max-width: 18rem;
				& > .Photo {
					.box(3.75rem; 3.75rem);
				}
				& > .Benefit {
					font-size: 1.125rem;
					.box(3.75rem; 3.75rem);
				}
			}
			&.size-3 {
				max-width: 20rem;
				& > .Photo {
					.box(5rem; 5rem);
				}
				& > .Benefit {
					font-size: 1.375rem;
					.box(5rem; 5rem);
				}
			}
			&.size-4 {
				max-width: 22rem;
				& > .Photo {
					.box(6.25rem; 6.25rem);
				}
				& > .Benefit {
					font-size: 1.625rem;
					.box(6.25rem; 6.25rem);
				}
			}
			&.size-5 {
				max-width: 24rem;
				& > .Photo {
					.box(7.5rem; 7.5rem);
				}
				& > .Benefit {
					font-size: 1.875rem;
					.box(7.5rem; 7.5rem);
				}
			}
		}
	}
	&--button {
		.grid(1 span; 1 span; center; start);
		.box(block; auto; auto; none);
		& > .UiButton {
			.box(100%);
		}
		@media all and (min-width: @laptop) {
			.grid(1 span; 1 span; center; center);
		}
	}
}

a.Benefits--list--Item {
	.box-shadow(0 0 0 0 fade(@ColorBase, 25%));
	.transition(all; 250ms; 'sine');
	&:hover {
		.translateY(-0.1875rem);
		.box-shadow(0 1.5rem 3rem -1.5rem fade(@ColorBase, 25%));
	}
}
</style>
