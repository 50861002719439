import { articlesService } from '~/services/articles';
import { Articles } from '~/models/articles';

export const useArticlesState = (limit: number | undefined = undefined) => ({
	articlesState: useState<Articles.Model[]>(
		limit ? `articlesState__${limit}` : 'articlesState',
		() => []
	),
	articlesLoaded: useState<boolean>(`articlesLoaded__${limit}`, () => false),
	articlesPending: useState<boolean>(`articlesPending__${limit}`, () => true),
});

export const useArticles = (limit: number | undefined = undefined) => {
	const { articlesState, articlesLoaded, articlesPending } = useArticlesState(limit);

	/**
	 * Fetch articles
	 */
	const fetchArticles = async () => {
		if (articlesLoaded.value) return;

		articlesPending.value = true;

		articlesState.value = await articlesService.fetch(limit);

		articlesLoaded.value = true;
		articlesPending.value = false;
	};

	/**
	 * Refresh articles
	 */
	const refreshArticles = async () => {
		articlesLoaded.value = false;

		await fetchArticles();
	};

	/**
	 * Fetch article by alias
	 */
	const article = ref<Articles.Model | undefined>(undefined);
	const fetchArticleByAlias = async (alias: string) => {
		article.value = await articlesService.fetchByAlias(alias);
	};

	return {
		articlesState,
		articlesPending,
		article,

		fetchArticles,
		refreshArticles,
		fetchArticleByAlias,
	};
};

export const useArticleState = (alias: string) => ({
	articleState: useState<Articles.Model>(`articleState__${alias}`, () => Articles.modelState),
	articleLoaded: useState<boolean>(`articleLoaded__${alias}`, () => false),
	articlePending: useState<boolean>(`articlePending__${alias}`, () => true),
});

export const useArticle = (alias: string) => {
	const { articleState, articleLoaded, articlePending } = useArticleState(alias);

	/**
	 * Get article by alias
	 */
	const fetchArticle = async (alias: string) => {
		if (articleLoaded.value) return;

		articleState.value = (await articlesService.fetchByAlias(alias)) || Articles.modelState;

		articleLoaded.value = true;
		articlePending.value = false;
	};

	/**
	 * Refresh article
	 */
	const refreshArticle = async () => {
		articleLoaded.value = false;

		await fetchArticle(alias);
	};

	return {
		articleState,
		articlePending,

		fetchArticle,
		refreshArticle,
	};
};
