<template lang="pug">
h2(v-if='lessThan("tablet")') {{ managerState.seo.header }}
div(class='Manager')
	UiProse(class='Text', :html='managerState.text', awesome)
	div(class='Name') {{ managerState.name }}
	div(class='Title') {{ managerState.title }}
	div(class='Advantages')
		div(class='Advantage', v-for='{ advantage } in managerState.advantages', :key='advantage')
			UiIcon(v-if='lessThan("tablet")', name='system/check', size='xs')
			span {{ advantage }}
	div(class='Photo')
		div(class='Image')
			UiImage(:image='managerState.photo', width='100%', lazy)
	div(v-if='managerState.form', class='Consultation')
		UiButton(theme='filled', size='md', rounded, @click='getManagerModal') {{ managerState.button }}

	div(v-if='greaterThan("desktop")', class='Documents')
		UiImage(
			v-for='document in documents',
			:key='document.id',
			:image='document.preview',
			width='200px',
			lazy
		)

SliderDocuments(v-if='lessThan("desktopMedium")')
</template>

<script lang="ts" setup>
import { Image } from '~/models/image';
import { Documents } from '~/models/documents';

const { lessThan, greaterThan } = usePlatform();
const { managerState, fetchManager } = useManager();
const { documentsList, fetchDocuments } = useDocuments();
const { modalState, modalShow } = useModal();

/**
 * Get manager modal
 */
const getManagerModal = () => {
	if (!managerState.value.form) return;

	modalState.value = {
		header: managerState.value.form.header,
		description: managerState.value.form.description,
		source: managerState.value.form.source,
		subject: managerState.value.form.subject,
		form: managerState.value.form.fields,
		target: managerState.value.form.target,
	};

	modalShow();
};

/**
 * Last 3 documents
 */
const documents = computed(() =>
	documentsList.value.reduce(
		(acc, document) => {
			if (acc.length < 3 && document.preview)
				acc.push({ ...document, preview: document.preview });

			return acc;
		},
		[] as Array<Omit<Documents.Model, 'preview'> & { preview: Image.Model }>
	)
);

await fetchManager();
await fetchDocuments();
</script>

<style lang="less" scoped>
.Manager {
	margin: 1.25rem 0 3rem 0;
	padding: 1.875rem 1rem;
	.box(grid; 100%; auto; @ColorWhite; 0.5rem; repeat(3, auto) 1fr auto; 1fr 7.5rem; start; start);
	.border-radius(@BorderRadiusSmall);
	& > .Text {
		margin: 0 0 1rem 0;
		.grid(1 2; 2 span);
	}
	& > .Name {
		.grid(2 3; 1 span);
		.text(fade(@ColorBase, 50%); 1.125em 1rem @bold);
	}
	& > .Title {
		margin: -0.25rem 0 0 0;
		.grid(3 4; 1 span);
		.text(fade(@ColorBase, 50%); 1.25em 0.75rem @medium);
	}
	& > .Advantages {
		margin: 0.5rem 0 0 0;
		.grid(4 5; 1 span);
		.box(grid; 100%; auto; none; 0.375rem; auto; 1fr; center; center);
		& > .Advantage {
			.grid(1 span);
			.box(grid; 100%; 100%; none; 0.5rem; auto; auto 1fr; start; start);
			& > .UiIcon {
				margin: 0.125rem 0 0 0;
				.grid(1 span);
				.text(@ColorSecondary);
			}
			& > span {
				.grid(1 span);
				.text(@ColorBase; 1.25em 0.75rem @medium);
			}
		}
	}
	& > .Photo {
		margin: 1.5rem 0 0 0;
		.grid(2 5; 1 span);
		.grid-aspect(1; 1);
		.box(block; 100%; auto; url('@/images/manager-bg.jpg'));
		.border-radius(@BorderRadiusFull);
		& > .Image {
			overflow: hidden;
			margin: -25% 0 0 0;
			.box(block; 100%; 125%);
			.border-bottom-radius(@BorderRadiusFull);
			& > img {
				margin: 0 0 0 -30%;
				object-fit: cover;
				object-position: top center;
				.box(160%; 100%);
			}
		}
	}
	& > .Consultation {
		padding: 1.5rem 0 0 0;
		.grid(1 span; 2 span);
		.box(block; 100%; auto; none);
		& > .UiButton {
			padding-top: 1rem;
			padding-bottom: 1rem;
			.box(100%);
		}
	}

	@media all and (min-width: @mobile-small) and (max-width: (@mobile - 1px)) {
		.box(grid; 100%; auto; @ColorWhite; 0.5rem; repeat(3, auto) 1fr auto; auto 7.5rem; start; start);
		& > .Text {
			.grid(1 2; 1 3);
		}
		& > .Name {
			.grid(2 3; 1 2);
			.text(none; none 1.125rem);
		}
		& > .Title {
			.grid(3 4; 1 2);
			.text(none; none 0.875rem);
		}
		& > .Advantages {
			.grid(4 5; 1 2);
			& > .Advantage {
				& > span {
					.text(none; none 0.875rem);
				}
			}
		}
		& > .Photo {
			.grid(2 5; 2 3);
		}
		& > .Consultation {
			.grid(1 span; 1 3);
		}
	}
	@media all and (min-width: @mobile) and (max-width: (@tablet-small - 1px)) {
		padding: 2rem 2rem;
		.box(grid; 100%; auto; @ColorWhite; 0.5rem; repeat(3, auto) 1fr auto; auto 30%; start; start);
		& > .Text {
			.grid(1 2; 1 3);
		}
		& > .Name {
			.grid(2 3; 1 2);
			.text(none; none 1.125rem);
		}
		& > .Title {
			.grid(3 4; 1 2);
			.text(none; none 0.875rem);
		}
		& > .Advantages {
			.grid(4 5; 1 2);
			& > .Advantage {
				& > span {
					.text(none; none 0.875rem);
				}
			}
		}
		& > .Photo {
			.grid(2 5; 2 3);
		}
		& > .Consultation {
			.grid(1 span; 1 3);
		}
	}
	@media all and (min-width: @tablet-small) and (max-width: (@tablet - 1px)) {
		margin-top: 2rem;
		padding: 2rem 2rem;
		.box(grid; 100%; auto; @ColorWhite; 0.5rem 1rem; repeat(3, auto) 1fr auto; 50% 1fr; start; start);
		& > .Text {
			margin: 1rem 0 0 0;
			.grid(4 5; 2 3);
		}
		& > .Name {
			.grid(1 2; 2 3);
			.text(none; none 1.125rem);
		}
		& > .Title {
			.grid(2 3; 2 3);
			.text(none; none 0.875rem);
		}
		& > .Advantages {
			.grid(3 4; 2 3);
		}
		& > .Photo {
			margin: -2rem 0 0 -2rem;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 40% 100%;
			.grid-aspect(unset);
			.grid(1 6; 1 2; start);
			.border-radius(unset);
			.border-left-radius(@BorderRadiusSmall);
			.calc(width; ~'calc(100% + 2rem)');
			.calc(height; ~'calc(100% + 4rem)');
			& > .Image {
				margin: -2rem 0 0 0;
				padding-right: 20%;
				.box(100%);
				.flexbox(center; center; center; nowrap row);
				.calc(height; ~'calc(100% + 2rem)');
				.border-radius(unset);
				.border-left-radius(@BorderRadiusSmall);
				& > img {
					max-width: 170%;
					margin: 0 0 0 0;
					object-fit: contain;
					object-position: bottom right;
					.box(auto; 100%);
				}
			}
		}
		& > .Consultation {
			.grid(1 span; 2 3);
		}
	}
	@media all and (min-width: @tablet) {
		padding: 2rem 2rem;
		.position(relative, 1);
		.box(grid; 100%; auto; @ColorWhite; 0.5rem 1rem; 1fr repeat(4, auto) 1fr; 24rem 1fr; start; start);
		& > .Text {
			.grid(4 5; 2 3);
		}
		& > .Name {
			max-width: 25rem;
			padding: 0 0 1rem 0;
			.grid(3 4; 2 3);
			.text(@ColorBase; 1.25em 2rem @bold; none; uppercase);
		}
		& > .Title {
			margin: 0;
			.grid(2 3; 2 3);
			.text(@ColorPrimary; none 0.875rem);
		}
		& > .Advantages {
			margin: 0;
			.grid(1 7; 1 2);
			.box(block; 100%; 100%; none);
			& > .Advantage {
				padding: 0.625rem 1rem;
				.box(none; auto; auto; @ColorWhite);
				.border-radius(@BorderRadiusFull);
				& > span {
					.text(@ColorBase; 1em 0.875rem @medium);
				}
				&:nth-child(1) {
					padding-right: 2rem;
					display: block;
					top: 38%;
					left: 2rem;
					.position(absolute, 1);
				}
				&:nth-child(2) {
					display: block;
					top: 75%;
					left: 2rem;
					.position(absolute, 3);
				}
				&:nth-child(3) {
					display: block;
					top: 55%;
					left: 15rem;
					.position(absolute, 3);
				}
			}
		}
		& > .Photo {
			margin: -2rem 0 0 -2rem;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 55% 100%;
			.grid-aspect(unset);
			.grid(1 7; 1 2; start);
			.border-radius(unset);
			.border-left-radius(@BorderRadiusSmall);
			.calc(width; ~'calc(100% + 2rem)');
			.calc(height; ~'calc(100% + 4rem)');
			& > .Image {
				margin: -2rem 0 0 0;
				.box(100%);
				.flexbox(center; center; center; nowrap row);
				.calc(height; ~'calc(100% + 2rem)');
				.border-radius(unset);
				.border-left-radius(@BorderRadiusSmall);
				& > img {
					margin: 1rem 0 0 3rem;
					object-fit: contain;
					object-position: bottom right;
					.position(relative, 2);
					.box(100%; 100%);
				}
			}
		}
		& > .Consultation {
			max-width: 18rem;
			padding: 0 0 0 0;
			.grid(5 6; 2 3);
		}
	}
	@media all and (min-width: @laptop-small) {
		.box(grid; 100%; auto; @ColorWhite; 0.5rem 1rem; 1fr repeat(4, auto) 1fr; 24rem 1fr auto 2fr; start; start);
		& > .Text {
			.grid(4 5; 3 4);
		}
		& > .Name {
			.grid(3 4; 3 4);
		}
		& > .Title {
			.grid(2 3; 3 4);
		}
		& > .Consultation {
			.grid(5 6; 3 4);
		}
	}
	@media all and (min-width: @desktop) {
		margin: 1.25rem 0 0 0;
		padding: 4rem 4rem;
		.box(grid; 100%; 36rem; @ColorWhite; 0.5rem 2rem; 2fr repeat(4, auto) 1fr; 25rem auto 1fr; start; start);
		& > .Text {
			.grid(4 5; 2 3);
		}
		& > .Name {
			max-width: 22.5rem;
			.grid(3 4; 2 3);
			.text(@ColorBase; 1.25em 2.5rem @bold; none; uppercase);
		}
		& > .Title {
			.grid(2 3; 2 3);
		}
		& > .Advantages {
			& > .Advantage {
				& > span {
					.text(@ColorBase; 1em 1rem @medium);
				}
				&:nth-child(1) {
					top: 29%;
					left: 0.5rem;
				}
				&:nth-child(2) {
					top: 75%;
					left: 2rem;
				}
				&:nth-child(3) {
					top: 52%;
					left: 17rem;
				}
			}
		}
		& > .Photo {
			margin: -4rem 0 0 -4rem;
			.calc(height; ~'calc(100% + 8rem)');
			& > .Image {
				margin: -3rem 0 0 0;
				.calc(height; ~'calc(100% + 3rem)');
				& > img {
					margin: 0 0 0 3rem;
					width: auto;
				}
			}
		}
		& > .Consultation {
			.grid(5 6; 2 3);
		}
		& > .Documents {
			.absolute(1; hidden; -4rem -4rem -4rem -2rem);
			.grid(1 7; 3 4);
			.box(block; auto; auto; none);
			.border-right-radius(@BorderRadiusSmall);
			& > img {
				object-fit: cover;
				.box(auto; auto);
				&:nth-child(1) {
					.absolute(1; none; 5% -15%);
				}
				&:nth-child(2) {
					.absolute(2; none; 30% 10%);
				}
				&:nth-child(3) {
					.absolute(3; none; 55% 35%);
				}
			}
		}
	}
	@media all and (min-width: @large-screen-small) {
		padding: 6rem 6rem;
		.box(grid; 100%; 38rem; @ColorWhite; 0.5rem 2rem; 2fr repeat(4, auto) 1fr; 28rem auto 1fr; start; start);
		& > .Advantages {
			& > .Advantage {
				& > span {
					.text(@ColorBase; 1em 1rem @medium);
				}
				&:nth-child(1) {
					top: 30%;
					left: 0.75rem;
				}
				&:nth-child(2) {
					top: 75%;
					left: 3rem;
				}
				&:nth-child(3) {
					top: 52%;
					left: 21rem;
				}
			}
		}
		& > .Photo {
			margin: -6rem 0 0 -6rem;
			.calc(height; ~'calc(100% + 12rem)');
		}
		& > .Documents {
			.absolute(1; hidden; -6rem -6rem -6rem -2rem);
		}
	}
}
</style>
