import { benefitsService } from '~/services/benefits';
import { Benefits } from '~/models/benefits';

export const useBenefitsState = () => ({
	benefitsState: useState<Benefits.Model[]>('benefitsState', () => []),
	benefitsLoaded: useState<boolean>('benefitsLoaded', () => false),
	benefitsPending: useState<boolean>('benefitsPending', () => true),
});

export const useBenefits = () => {
	const { benefitsState, benefitsLoaded, benefitsPending } = useBenefitsState();

	/**
	 * Fetch benefits
	 */
	const fetchBenefits = async () => {
		if (benefitsLoaded.value) return;

		benefitsPending.value = true;

		benefitsState.value = await benefitsService.fetch();

		benefitsLoaded.value = true;
		benefitsPending.value = false;
	};

	/**
	 * Refresh benefits
	 */
	const refreshBenefits = async () => {
		benefitsLoaded.value = false;

		await fetchBenefits();
	};

	return {
		benefitsState,
		benefitsPending,

		fetchBenefits,
		refreshBenefits,
	};
};
