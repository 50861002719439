import { Common } from '~/models/common';
import { Articles } from '~/models/articles';

const collection = 'articles';

export const articlesService = {
	fetch: async (limit: number | undefined = undefined): Promise<Articles.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Articles.Model>({
			collection,
			params: {
				limit,
				sort: '-date',
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'preview.*', 'image.*', 'form.*', 'seo.*'],
			},
		});
	},

	fetchByAlias: async (alias: string): Promise<Articles.Model | undefined> => {
		const { getSingletonItem } = useDirectusItems();

		const [response] = await getSingletonItem<[Articles.Model]>({
			collection,
			params: {
				filter: {
					alias: alias,
					status: Common.Status.PUBLISHED,
				},
				fields: [
					'*',
					'preview.*',
					'image.*',
					'elements.article_elements_id.*',
					'elements.article_elements_id.articleLink.alias',
					'elements.article_elements_id.articleLink.seo.header',
					'elements.article_elements_id.image.*',
					'elements.article_elements_id.employees.*',
					'elements.article_elements_id.employees.photo.*',
					'elements.article_elements_id.employees.form.*',
					'elements.article_elements_id.employees.seo.*',
					'elements.article_elements_id.services.*',
					'elements.article_elements_id.services.preview.*',
					'elements.article_elements_id.services.form.*',
					'elements.article_elements_id.services.seo.*',
					'elements.article_elements_id.services.group.services_group_id.id',
					'units.*',
					'form.*',
					'seo.*',
				],
			},
		});

		return response || undefined;
	},
};
