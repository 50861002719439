<template lang="pug">
div(class='ArticlesUnit')
	div(class='ArticlesUnit--Header')
		h2 {{ variablesList.articlesTitle }}
	div(class='ArticlesUnit--Link')
		UiButton(
			:to='getPageUrlByType("articles")',
			size='md',
			variant='secondary',
			iconRight='system/arrow-top-right',
			iconRightSize='sm',
			rounded
		) Посмотреть все

	div(class='ArticlesUnit--Slider')
		SliderArticles(:articles='articlesState')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();
const { articlesState, fetchArticles } = useArticles();
const { getPageUrlByType, fetchResolver } = useResolver();

/**
 * Define fetch
 */
await fetchVariables();
await fetchArticles(10);
await fetchResolver();
</script>

<style lang="less" scoped>
.ArticlesUnit {
	.box(grid; 100%; auto; none; 1.5rem 0; auto; 100%; center; start);
	&--Header {
		.grid(1 2, 1 span);
		.box(block; auto; auto; none);
	}
	&--Slider {
		.grid(2 3, 1 span);
		.box(block; 100%; auto; none);
	}
	&--Link {
		.grid(3 4, 1 span);
		.box(block; auto; auto; none);
	}

	@media all and (min-width: @tablet-small) {
		.box(grid; 100%; auto; none; 1.5rem 0; auto; 1fr 1fr; center; start);

		&--Header {
			.grid(1 2; 1 2; center; start);
		}
		&--Slider {
			.grid(2 3; 1 3);
		}
		&--Link {
			.grid(1 2; 2 3; center; end);
		}
	}
	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; none; 3rem 0; auto; 1fr 1fr; center; start);
	}
}
</style>
