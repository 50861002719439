import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';
import { Seo } from '~/models/seo';
import { ArticleElements } from '~/models/article-elements';

export namespace Articles {
	export const units = {
		ABOUT: 'about',
		ARTICLES: 'articles',
		BENEFITS: 'benefits',
		CASES: 'cases',
		CHOOSE: 'choose',
		CONSULTATION_EMPLOYEES: 'consultation-employees',
		CONSULTATION_VNZH: 'consultation-vnzh',
		HISTORY: 'history',
		MANAGER: 'manager',
		MANAGER2: 'manager2',
		MIGRATION: 'migration',
		PRACTICE: 'practice',
		REPUTATION: 'reputation',
		REVIEWS_GROUP: 'reviews-group',
		REVIEWS: 'reviews',
		VICTORIES: 'victories',
		VIDEO_REVIEWS: 'video-reviews',
	} as const;

	export type Unit = (typeof units)[keyof typeof units];

	export type Model = Common.Model & {
		alias: string;
		date: string;
		preview: Image.Model;
		image: Image.Model;
		article: string;
		elements: { article_elements_id: ArticleElements.Model }[];
		units: { unit: Unit }[];
		form: Form.Model;
		seo: Seo.Model;
	};

	export const modelState: Model = {
		...Common.modelState,
		alias: '',
		date: '',
		preview: Image.modelState,
		image: Image.modelState,
		article: '',
		elements: [],
		units: [],
		form: Form.modelState,
		seo: Seo.modelState,
	};
}
