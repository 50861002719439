<template lang="pug">
div(class='SliderArticles')
	UiCarousel(
		class='SliderArticles--Carousel',
		:perView='greaterThan("tablet") ? 4.2 : greaterThan("mobileWide") ? 2.8 : greaterThan("mobileMedium") ? 1.8 : 1.1',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(v-for='article in articles', :key='article.id', v-bind='carouselItemProps')
					div(class='SliderArticles--Carousel--Item')
						NuxtLink(:to='getArticlesPageUrl(article.alias)', class='Article')
							UiImage(:image='article.preview', width='200px', lazy)
							div(v-if='article.seo', class='Name') {{ article.seo.label }}
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';
import { Articles } from '~/models/articles';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define props
 */
const { articles = [] } = defineProps<{
	articles: Articles.Model[];
}>();

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { getPageUrlByType, fetchResolver } = useResolver();

/**
 * Get articles page url by alias
 */
const getArticlesPageUrl = (alias: string) => joinURL(getPageUrlByType('articles') || '', alias);

/**
 * Define fetch
 */
await fetchResolver();
</script>

<style lang="less" scoped>
.SliderArticles {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&--Carousel {
		.grid(1 span; 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			.box(grid; 100%; 100%; none; 0; 1fr; 1fr; stretch; start);
			& > .Article {
				text-decoration: none;
				.grid(1 span, 1 span);
				.box(grid; 100%; 100%; none; 0.5rem; auto 1fr; 1fr; stretch; start);
				.transition(all; 250ms; 'sine');

				& > img {
					object-fit: cover;
					filter: grayscale(100%);
					.grid-aspect(3; 2);
					.grid(1 span);
					.box(block; 100%; auto);
					.border-radius(@BorderRadiusSmall);
					.transition(all; 250ms; 'sine');
					.box-shadow(0 0 0 0 fade(@ColorBase, 25%));
				}
				& > .Name {
					.grid(1 span);
					.text(@ColorBase; 1.25em 1rem @medium);
				}
				&:hover {
					.translateY(-0.1875rem);
					& > img {
						filter: grayscale(50%);
						.box-shadow(0 1.5rem 3rem -1.5rem fade(@ColorBase, 25%));
					}
					& > .Name {
						.text(@ColorPrimary);
					}
				}
			}
		}
	}
}
</style>
